import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { MdClose as CloseIcon } from 'react-icons/md';
import { translate } from 'react-polyglot';
import styled from 'styled-components';

import { fadeIn, fadeOut } from './animations';
import ExpandableContent from './ExpandableContent';
import WithBackgroundImage from './Image/WithBackgroundImage';
import ViewMore from './ViewMore';

const Container = styled.div`
    display: flex;
    flex-flow: ${({ imagePosition }) =>
        imagePosition === 'left' ? 'row-reverse' : 'row'};
    margin: 2rem 0 3rem 0;
    position: relative;
    min-width: 100%;
    width: 100%;

    @media (max-width: 600px) {
        flex-flow: column;
        margin: 2rem 0 1rem;
    }
`;

const ImageBackground = styled(WithBackgroundImage)`
    background-position: 50% 50%;
    background-size: cover;
    height: 530px;
    width: 90%;
    position: absolute;
    z-index: 1;

    left: ${({ imagePosition }) => (imagePosition === 'left' ? 'auto' : '7%')};
    right: ${({ imagePosition }) => (imagePosition === 'left' ? '7%' : 'auto')};

    @media (max-width: 600px) {
        position: relative;
        width: 100%;
        height: 184px;
        margin-left: 0;
        margin-right: 0;
        left: auto;
        right: auto;
    }
`;

const InnerContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    border-bottom: 1px #d4d4ce solid;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    margin: 7% 0 0 0;
    padding: 4rem;
    width: 35%;
    z-index: 1;
    position: relative;
    opacity: ${({ opacity }) => (opacity ? '100%' : '0%')};

    :hover .arrow-right,
    :active .arrow-right {
        margin-left: 1.5rem;
    }

    &:after {
        transition: all 0.3s ease-in-out;
        transition-property: opacity, top, right, bottom, left;
        position: absolute;
        box-shadow: -1px 2px 24px 0 rgba(0, 0, 0, 0.24);
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-color: ${({ theme }) => theme.white};
        opacity: 0;
    }

    &:hover,
    &:active {
        box-bottom: none;
    }
    &:hover:after,
    &:active:after {
        opacity: 1;
        @media (min-width: 601px) {
            top: -1%;
            left: -1%;
            right: -1%;
            bottom: -1%;
        }
    }

    @media (max-width: 600px) {
        margin: 0;
        padding: 1rem;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        width: auto;
    }
`;

/* title component */
const Title = styled.div`
    color: ${({ color }) => color};
    font-size: 1.2rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-style: normal;

    @media (max-width: 600px) {
        text-align: center;
    }
`;

const SectionTitle = styled.div`
    color: ${({ theme }) => theme.text};
    font-size: 1.2rem;
    margin: 0.5rem 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-style: bold;

    @media (max-width: 600px) {
        text-align: center;
    }
`;

const ButtonContainer = styled.div`
    margin-top: 2rem;
`;

const Modal = styled.div`
    background: ${({ theme }) => theme.whiteDark};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: 0.3s ${({ open }) => (open ? fadeIn : fadeOut)} ease-out;
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    transition: visibility 0.3s ease-out;
    z-index: 1000;
    overflow-y: auto;
    padding-right: 19px;
`;

const ModalContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 960px;
    margin: 3em auto;
    @media (max-width: 600px) {
        margin: 6%;
    }
`;

const ModalTitle = styled(Title)`
    @media (max-width: 600px) {
        text-align: left;
    }
`;

const ModalSectionTitle = styled(SectionTitle)`
    text-transform: none;

    @media (max-width: 600px) {
        text-align: left;
    }
`;

const ModalCloseButton = styled.button`
    background: transparent;
    border: 0;
    align-self: flex-end;
    cursor: pointer;
    top: 3rem;
    right: 3rem;
    position: fixed;

    @media (max-width: 600px) {
        top: 1rem;
        right: 1rem;
    }
`;

const ModalCloseButtonIcon = styled(CloseIcon)`
    color: ${({ color }) => color};
    font-size: 2.5rem;
`;

const Separator = styled.hr`
    border: 1px solid ${({ theme }) => theme.grayDark};
    margin: 1rem 0;
`;

class AboutUs extends Component {
    state = { open: false, opacity: true };

    handleClick = () => {
        this.setState(({ open }) => ({ open: !open }));
    };

    handleRightClick = event => {
        const opacity = this.state.opacity;
        if (event.ctrlKey && opacity) {
            this.setState({ opacity: false });
        }
        if (!opacity) {
            this.setState({ opacity: true });
        }
        event.preventDefault();
    };

    render() {
        const {
            children,
            color,
            title,
            image,
            imagePosition,
            expandableContents,
            t,
        } = this.props;
        const { open } = this.state;

        return (
            <Container imagePosition={imagePosition}>
                <ImageBackground image={image} imagePosition={imagePosition} />
                <InnerContainer
                    imagePosition={imagePosition}
                    onClick={this.handleClick}
                    onContextMenu={this.handleRightClick}
                    opacity={this.state.opacity}
                >
                    <Title color={color}>{t('aboutus.title')}</Title>
                    <SectionTitle>{title}</SectionTitle>
                    {children}
                    <ButtonContainer>
                        <ViewMore
                            label={t('aboutus.learn_more')}
                            color={color}
                        />
                    </ButtonContainer>
                </InnerContainer>
                <Modal
                    open={open}
                    role="dialog"
                    aria-modal
                    aria-labelledby={`modal_${title}`}
                >
                    <ModalCloseButton
                        aria-label={t('modal.close')}
                        onClick={this.handleClick}
                    >
                        <ModalCloseButtonIcon color={color} />
                    </ModalCloseButton>
                    <ModalContentContainer>
                        <ModalTitle id={`modal_${title}`} color={color}>
                            {t('aboutus.title')}
                        </ModalTitle>
                        <ModalSectionTitle>{title}</ModalSectionTitle>
                        {children}
                        {expandableContents.map((expandableContent, index) => (
                            <div key={expandableContent.title}>
                                <ExpandableContent
                                    color={color}
                                    title={expandableContent.title}
                                    content={expandableContent.content}
                                />
                                {index < expandableContents.length - 1 && (
                                    <Separator />
                                )}
                            </div>
                        ))}
                    </ModalContentContainer>
                </Modal>
            </Container>
        );
    }
}

AboutUs.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    imagePosition: PropTypes.string.isRequired,
    expandableContents: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            content: PropTypes.string.isRequired,
        }),
    ).isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(AboutUs);
