import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../components/i18n/withSetLocale';

import Layout from '../../components/layout';
import HomePage from '../../components/HomePage';

const Home = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <HomePage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Home.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Home);

export const pageQuery = graphql`
    query HomeQuery {
        site {
            siteMetadata {
                siteUrl
            }
        }

        posts: allMdx(
            sort: { order: DESC, fields: [fileAbsolutePath] }
            limit: 3
            filter: { fileRelativePath: { glob: "**/blog/**" } }
        ) {
            edges {
                node {
                    id
                    date
                    path
                    frontmatter {
                        title
                        authors
                        tags
                        excerpt
                        image
                    }
                }
            }
        }
        testimonials: allMdx(
            filter: { fileRelativePath: { glob: "**/testimonials/**" } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        text
                        text
                        logo
                        person
                        personImage
                        lang
                    }
                }
            }
        }
        values: allMdx(
            filter: {
                fileRelativePath: { glob: "**/values/**" }
                frontmatter: { theme: { regex: "/.+/" } }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        theme
                        text
                        image
                        lang
                        order
                    }
                }
            }
        }
        showcases: allMdx(
            filter: {
                fileRelativePath: { glob: "**/showcases/**" }
                frontmatter: { customer: { regex: "/.+/" } }
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        customer
                        title
                        text
                        image
                        lang
                        link
                    }
                }
            }
        }
        team: allMdx(
            sort: { fields: fileAbsolutePath }
            filter: { fileRelativePath: { glob: "**/team/**" } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        name
                        fullname
                        shortname
                        link
                        picture
                        picture_sp
                        retired
                    }
                }
            }
        }

        customers: allFile(
            sort: { fields: absolutePath }
            filter: {
                sourceInstanceName: { eq: "images" }
                relativePath: { glob: "customers/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    name
                    childImageSharp {
                        fluid(maxWidth: 200, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

        homeImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                relativePath: { glob: "home/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 1280, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        valueImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                relativePath: { glob: "values/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 1280, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
        showcaseImages: allFile(
            filter: {
                sourceInstanceName: { eq: "images" }
                relativePath: { glob: "showcases/**" }
            }
        ) {
            edges {
                node {
                    publicURL
                    absolutePath
                    name
                    childImageSharp {
                        fluid(maxWidth: 1280, quality: 80) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }
`;
