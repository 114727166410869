import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import bubbleImage from '../../static/images/home/home-bubble.png';

const Scene = styled.div`
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    width: 165px;
    height: 3rem;
    perspective: 600px;

    @media (max-width: 600px) {
        height: 2rem;
        width: 115px;
    }
`;

const Container = styled.div`
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    position: relative;

    > div {
        position: absolute;
        width: 100%;
        height: 100%;
        color: white;
        text-align: center;
        backface-visibility: hidden;
        transform: rotateX(0deg);

        &.alternative {
            transform: rotateY(180deg);

            .tooltip {
                position: absolute;
                background: url(${bubbleImage});
                background-size: contain;
                font-size: 10px;
                top: -150px;
                right: -70px;
                width: 180px;
                height: 280px;
                color: ${({ theme }) => theme.text};
                padding: 4em 2em;
                line-height: 2em;
                box-sizing: border-box;
            }
        }
    }

    &:hover {
        transform: rotateY(180deg);
    }
`;

const HiddenTooltip = ({ children, alternative, tooltip }) => (
    <Scene>
        <Container>
            <div className="default">{children}*</div>
            <div className="alternative">
                <div className="tooltip">{tooltip}</div>
                {alternative}
            </div>
        </Container>
    </Scene>
);

HiddenTooltip.propTypes = {
    children: PropTypes.node,
    alternative: PropTypes.string,
    tooltip: PropTypes.string,
};

export default HiddenTooltip;
