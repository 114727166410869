import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Carousel from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Showcase from './Showcase';

const Container = styled.div`
    .showcase {
        .container {
            min-height: 350px;
            display: flex;
            flex-direction: column;
            position: relative;
            margin: 1rem 0 0 0;
            text-align: center;
            @media (max-width: 600px) {
                min-height: 250px;
            }
        }
        .background: {
            min-height: 350px;
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            z-index: -1;
            @media (max-width: 600px) {
                min-height: 250px;
            }
        }
        .title-container {
            position: relative;
            @media (max-width: 600px) {
                min-height: 250px;
            }
            .title {
                color: ${({ theme }) => theme.white};
                font-family: 'Montserrat', sans-serif;
                font-size: 1.5rem;
                font-weight: normal;
                margin: 3rem 0 1.5rem 0;
                @media (max-width: 600px) {
                    margin: 4rem 0 1.5rem 0;
                }
            }
        }
        .slider-button {
            background: none;
            border: none;
            cursor: pointer;
            font-size: 4rem;
            outline: none;
            padding: 0;
            margin: 0;
            position: absolute;
            z-index: 10000;
            top: 80%;
            @media (max-width: 600px) {
                top: 40%;
            }
            &.left {
                left: 0;
                right: 'unset';
            }
            &.right {
                left: 'unset';
                right: 0;
            }
        }
        .product-title {
            color: ${({ theme }) => theme.white};
            font-family: 'Montserrat', sans-serif;
            font-size: 2.5rem;
            font-weight: 300;
            margin: 1.5rem 64px;
            line-height: 1em;
            @media (max-width: 600px) {
                font-size: 1.3rem;
                margin: 0.5rem 3.5rem 0.5rem 3.5rem;
            }

            @media (max-width: 320px) {
                font-size: 1.3rem;
                margin: 0.5rem 3rem 0.5rem 3rem;
            }
        }
        .content {
            background-color: ${({ theme }) => theme.white};
            display: flex;
            flex-direction: column;
            margin: 0 auto 0 auto;
            z-index: 11;
            padding: 1rem 3rem;
            max-width: 70%;

            @media (max-width: 600px) {
                max-width: none;
                font-size: 0.8rem;
                padding: 1rem;
            }
        }
        .read-more {
            background-color: transparent;
            color: ${({ theme }) => theme.blue};
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
            font-size: 0.8125rem;
            font-weight: bold;
            letter-spacing: 2px;
            padding: 0.6875rem 2rem;
            text-transform: uppercase;
            text-decoration: none;
            transition: all 0.3s ease-in-out;
            border: solid 2px ${({ theme }) => theme.blue};
            &:hover {
                background-color: ${({ theme }) => theme.blue};
                color: ${({ theme }) => theme.white};
            }
        }
        .max-width-container {
            width: 100%;
            margin: 0 auto;
            padding: 0 1rem;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            max-width: 1280px;
            @media (max-width: 600px) {
                width: auto;
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }
        .button-list {
            margin: 2rem 0;
        }
    }
`;

const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    arrows: false,
    adaptiveHeight: true,
};

class ShowcaseList extends Component {
    storeCarouselRef = node => {
        this.carousel = node;
    };

    handleNextSlide = event => {
        event.preventDefault();
        this.carousel.slickNext();
    };

    handlePreviousSlide = event => {
        event.preventDefault();
        this.carousel.slickPrev();
    };

    render() {
        const { showcases, images } = this.props;
        return (
            <Container>
                <Carousel {...settings} ref={this.storeCarouselRef}>
                    {showcases.map(showcase => {
                        const image = images.find(image =>
                            image.absolutePath.includes(showcase.image),
                        );
                        return (
                            <div key={showcase.id}>
                                {/* The above div is needed for react-slick not to mess with Showcase style */}
                                <Showcase
                                    showcase={showcase}
                                    image={image}
                                    onNextSlide={this.handleNextSlide}
                                    onPreviousSlide={this.handlePreviousSlide}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </Container>
        );
    }
}

ShowcaseList.propTypes = {
    showcases: PropTypes.arrayOf(
        PropTypes.shape({
            image: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            customer: PropTypes.string.isRequired,
        }),
    ).isRequired,
    images: PropTypes.array,
};

export default ShowcaseList;
