import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import BlogTagList from './BlogTagList';
import { Card, CardTitle } from '../Card';
import Authorship from './Authorship';

const BlogCard = styled(Card)`
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px;
    max-width: 500px;
    margin: 0 0.5rem 0.5rem;
    padding: 25px;
`;

const BlogCardTitle = styled(CardTitle)`
    margin: 0;
`;

const BlogLink = styled(Link)`
    text-decoration: none;
    color: inherit;
`;

const BlogPostCard = ({ post: { tags, title, authors, date, path } }) => (
    <BlogCard>
        <BlogCardTitle>
            <BlogLink to={path}>{title}</BlogLink>
        </BlogCardTitle>
        <BlogTagList tags={tags} />
        <Authorship
            authors={authors}
            date={date}
            linkToAuthorBlogPages={true}
        />
    </BlogCard>
);

BlogPostCard.propTypes = {
    post: PropTypes.object.isRequired,
};

export default BlogPostCard;
