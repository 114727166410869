import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    FaArrowDown as IconArrowDown,
    FaArrowUp as IconArrowUp,
} from 'react-icons/fa';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;
const Title = styled.button`
    background: none;
    border: none;
    outline: none;
    color: ${({ color }) => color};
    cursor: pointer;
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    text-align: left;
`;

const StyledIconArrowDown = styled(IconArrowDown)`
    flex-shrink: 0;
    font-size: 2rem;
    margin-left: auto;
    color: ${({ color }) => color};
`;

const StyledIconArrowUp = styled(IconArrowUp)`
    color: ${({ color }) => color};
    flex-shrink: 0;
    font-size: 2rem;
    margin-left: auto;
`;

const Content = styled.div`
    height: 'auto';
    max-height: ${({ open }) => (open ? 'auto' : 0)};
    opacity: ${({ open }) => (open ? 1 : 0)};
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
`;

class ExpandableContent extends Component {
    state = { open: false };

    handleClick = event => {
        event.preventDefault();
        this.setState(({ open }) => ({ open: !open }));
    };

    render() {
        const { open } = this.state;
        const { title, content, color } = this.props;

        return (
            <Container>
                <Title color={color} onClick={this.handleClick}>
                    <span>{title}</span>
                    {open ? (
                        <StyledIconArrowUp color={color} />
                    ) : (
                        <StyledIconArrowDown color={color} />
                    )}
                </Title>

                <Content
                    open={open}
                    dangerouslySetInnerHTML={{
                        __html: content,
                    }}
                />
            </Container>
        );
    }
}

ExpandableContent.propTypes = {
    color: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default ExpandableContent;
