import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import styled from 'styled-components';

import {
    MdNavigateBefore as IconPrevious,
    MdNavigateNext as IconNext,
} from 'react-icons/md';

import theme from '../theme';
import WithBackgroundImage from '../Image/WithBackgroundImage';

const previousIconStyle = {
    color: theme.white,
};

const nextIconStyle = {
    color: theme.white,
};

const ContainerWithImageBackground = styled(WithBackgroundImage)`
    min-height: 350px;
    z-index: 10;

    &:after {
        display: block;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
        );
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        max-height: 350px;
    }
`;

const Showcase = ({
    onNextSlide,
    onPreviousSlide,
    showcase: { customer, text, title, link },
    image,
    t,
}) => (
    <div className="showcase">
        <div className="container">
            <ContainerWithImageBackground
                image={image}
                imageStyle={{ maxHeight: 350 }}
            >
                <div className="title-container max-width-container">
                    <div
                        className="slider-button left"
                        onClick={onPreviousSlide}
                    >
                        <IconPrevious
                            color={theme.white}
                            style={previousIconStyle}
                        />
                    </div>
                    <h3 className="title">
                        {customer === 'marmelab'
                            ? t('showcases.product')
                            : t('showcases.title')}
                    </h3>
                    <h2 className="product-title">
                        {customer}: {title}
                    </h2>
                    <div className="slider-button right" onClick={onNextSlide}>
                        <IconNext color={theme.white} style={nextIconStyle} />
                    </div>
                </div>
                <div className="max-width-container">
                    <div className="content">
                        <p>{text}</p>
                        <div className="button-list">
                            <a className="read-more" href={link} tabIndex="-1">
                                {t('showcases.readmore')}
                            </a>
                        </div>
                    </div>
                </div>
            </ContainerWithImageBackground>
        </div>
    </div>
);

Showcase.propTypes = {
    onNextSlide: PropTypes.func.isRequired,
    onPreviousSlide: PropTypes.func.isRequired,
    showcase: PropTypes.shape({
        text: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        customer: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
};

export default translate()(Showcase);
