import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { translate } from 'react-polyglot';
import compose from 'recompose/compose';
import mapProps from 'recompose/mapProps';
import styled from 'styled-components';

import AboutUs from './AboutUs';
import AppBar from './AppBar';
import BlogPostCard from './Blog/BlogPostCard';
import { SecondaryButton } from './Button';
import CallToAction from './CallToAction';
import Customers from './Customers';
import Footer from './Footer';
import BaseHeaderImage from './HeaderImage';
import HiddenAsterisk from './HiddenAsterisk';
import Makers from './Makers';
import MaxWidthContainer from './MaxWidthContainer';
import ShowcaseList from './Showcases/ShowcaseList';
import TopBar from './TopBar';
import ValueList from './Values/ValueList';
import logoImage from '../../static/images/logo-full.png';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const BlogContainer = styled(MaxWidthContainer)`
    display: flex;
`;

const HeaderImage = styled(BaseHeaderImage)`
    > div:first-child {
        background: linear-gradient(
            rgba(0, 0, 0, 0.4) 60%,
            rgba(243, 243, 243, 0.7) 77%,
            rgb(242, 242, 242) 87%
        );
    }
`;

const Intro = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 8rem 0;

    @media (max-width: 600px) {
        margin: 4rem 0;
    }
`;
const Title = styled.h1`
    color: ${({ theme }) => theme.white};
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    font-weight: 300;
    line-height: 3rem;
    text-align: center;
    margin: 1.3rem 0;

    @media (max-width: 600px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 3rem;
    }

    @media (max-width: 320px) {
        font-size: 1.75rem;
        line-height: 2rem;
        margin: 0 2rem;
    }
`;

const TagLine = styled.p`
    color: ${({ theme }) => theme.white};
    font-size: 1rem;
    max-width: 55%;
    text-align: center;
    margin: 0;

    @media (max-width: 600px) {
        max-width: 90%;
    }

    @media (min-width: 1281px) {
        font-size: 1.3rem;
    }
`;

const BlogPosts = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 1rem 0;

    @media (max-width: 600px) {
        flex-direction: column;
    }

    @media (min-width: 601px) {
        > div:first-child {
            margin-left: 0;
        }

        > div:last-child {
            margin-right: 0;
        }

        > div {
            flex-basis: 33.33%;
        }
    }
`;

const MorePostsButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const MorePostsButton = styled(SecondaryButton)`
    display: inline-block;
    text-align: center;
    margin: 0 auto;

    @media (max-width: 600px) {
        display: block;
        margin: 0 1rem;
    }
`;

const CustomersTitle = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 300;
    margin-top: 5rem;
    margin-bottom: 2rem;
    text-align: center;
    text-transform: uppercase;

    @media (max-width: 600px) {
        font-size: 1.5rem;
        line-height: 2rem;
    }
`;

const MainTitle = {
    en: <Title>Marmelab is a Digital Innovation Studio</Title>,
    fr: (
        <Title>
            {"Marmelab est un atelier d'innovation "}
            <HiddenAsterisk
                alternative="numérique"
                tooltip="On sait que digital ne veut rien dire mais notre boss nous a obligé à le garder"
            >
                digitale
            </HiddenAsterisk>
        </Title>
    ),
};

const Home = ({
    posts,
    values,
    valueImages,
    showcases,
    showcaseImages,
    homeImages,
    customers,
    siteUrl,
    team,
    locale,
    t,
}) => (
    <Container>
        <Helmet title={t('seo.defaultTitle')}>
            <html lang={locale} />
            <link
                rel="alternate"
                hrefLang={locale === 'en' ? 'fr' : 'en'}
                href={`${siteUrl}/${locale == 'en' ? 'fr' : 'en'}/`}
            />
            <meta name="description" content={t('seo.defaultDescription')} />
            <meta name="twitter:image" content={logoImage} />
            <meta property="og:image" content={logoImage} />
        </Helmet>
        <TopBar role="presentation" />
        <header>
            <HeaderImage image={homeImages['home-bg']}>
                <AppBar inverted />
                <MaxWidthContainer>
                    <Intro>
                        {MainTitle[locale]}
                        <TagLine>{t('home.tagline')}</TagLine>
                    </Intro>
                </MaxWidthContainer>
                <BlogContainer>
                    <BlogPosts>
                        {posts
                            ? posts.map(post => (
                                  <BlogPostCard key={post.id} post={post} />
                              ))
                            : null}
                    </BlogPosts>
                </BlogContainer>
                <MorePostsButtonContainer>
                    <MorePostsButton to={`/${locale}/blog`}>
                        {t('viewMoreFromBlog')}
                    </MorePostsButton>
                </MorePostsButtonContainer>
            </HeaderImage>
        </header>
        <div role="main">
            <MaxWidthContainer>
                <AboutUs
                    title={t('aboutus.innovation')}
                    color="#F47E20"
                    image={homeImages['home-consulting']}
                    imagePosition="right"
                    expandableContents={[
                        {
                            title: t(
                                'aboutus.innovationExpandableContent1.title',
                            ),
                            content: t(
                                'aboutus.innovationExpandableContent1.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.innovationExpandableContent2.title',
                            ),
                            content: t(
                                'aboutus.innovationExpandableContent2.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.innovationExpandableContent3.title',
                            ),
                            content: t(
                                'aboutus.innovationExpandableContent3.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.innovationExpandableContent4.title',
                            ),
                            content: t(
                                'aboutus.innovationExpandableContent4.content',
                            ),
                        },
                    ]}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('aboutus.innovationContent'),
                        }}
                    />
                </AboutUs>
                <AboutUs
                    title={t('aboutus.development')}
                    color="#359C9C"
                    image={homeImages['home-framework']}
                    imagePosition="left"
                    expandableContents={[
                        {
                            title: t(
                                'aboutus.developmentExpandableContent1.title',
                            ),
                            content: t(
                                'aboutus.developmentExpandableContent1.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.developmentExpandableContent2.title',
                            ),
                            content: t(
                                'aboutus.developmentExpandableContent2.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.developmentExpandableContent3.title',
                            ),
                            content: t(
                                'aboutus.developmentExpandableContent3.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.developmentExpandableContent4.title',
                            ),
                            content: t(
                                'aboutus.developmentExpandableContent4.content',
                            ),
                        },
                    ]}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('aboutus.developmentContent'),
                        }}
                    />
                </AboutUs>
                <AboutUs
                    title={t('aboutus.consulting')}
                    color="#52489C"
                    image={homeImages['home-greenframe']}
                    imagePosition="right"
                    expandableContents={[
                        {
                            title: t(
                                'aboutus.consultingExpandableContent1.title',
                            ),
                            content: t(
                                'aboutus.consultingExpandableContent1.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.consultingExpandableContent2.title',
                            ),
                            content: t(
                                'aboutus.consultingExpandableContent2.content',
                            ),
                        },
                        {
                            title: t(
                                'aboutus.consultingExpandableContent3.title',
                            ),
                            content: t(
                                'aboutus.consultingExpandableContent3.content',
                            ),
                        },
                    ]}
                >
                    <div
                        dangerouslySetInnerHTML={{
                            __html: t('aboutus.consultingContent'),
                        }}
                    />
                </AboutUs>
            </MaxWidthContainer>
            <ValueList values={values} images={valueImages} />
            <Makers team={team} locale={locale} />
            <ShowcaseList showcases={showcases} images={showcaseImages} />
            <CustomersTitle>{t('customers.title')}</CustomersTitle>
            <Customers images={customers} />
            <CallToAction />
        </div>
        <Footer />
    </Container>
);

Home.propTypes = {
    posts: PropTypes.array.isRequired,
    testimonials: PropTypes.array,
    values: PropTypes.array.isRequired,
    showcases: PropTypes.array.isRequired,
    homeImages: PropTypes.object.isRequired,
    customers: PropTypes.array,
    valueImages: PropTypes.array,
    showcaseImages: PropTypes.array,
    team: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    siteUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
};

const indexByName = members =>
    members.reduce(
        (acc, { node: { frontmatter } }) => ({
            ...acc,
            [frontmatter.name]: frontmatter,
        }),
        {},
    );

export default compose(
    mapProps(({ data, locale }) => {
        const team = indexByName(data.team.edges);

        const activeTeam = data.team.edges
            .filter(({ node: { frontmatter } }) => !frontmatter.retired)
            .map(({ node: { frontmatter } }) => team[frontmatter.name]);

        return {
            posts: data.posts.edges.map(
                ({ node: { id, path, date, frontmatter } }) => ({
                    id,
                    path,
                    date,
                    ...frontmatter,
                    authors: frontmatter.authors.map(author => team[author]),
                }),
            ),
            siteUrl: data.site.siteMetadata.siteUrl,
            homeImages: data.homeImages.edges.reduce((acc, { node }) => {
                acc[node.name] = node;
                return acc;
            }, {}),
            customers: data.customers.edges.map(({ node }) => node),
            values: data.values.edges
                .map(({ node }) => ({
                    id: node.id,
                    ...node.frontmatter,
                }))
                .filter(value => value.lang === locale),
            valueImages: data.valueImages.edges.map(({ node }) => node),
            showcases: data.showcases.edges
                .map(({ node }) => ({
                    id: node.id,
                    ...node.frontmatter,
                }))
                .filter(showcase => showcase.lang === locale),
            showcaseImages: data.showcaseImages.edges.map(({ node }) => node),
            team: activeTeam,
            locale,
        };
    }),
    translate(),
)(Home);
